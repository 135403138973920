import React, { useMemo } from "react";
import http from "@/services/http";

import { useQueryParams } from "@/services/hooks";
import { Table, Button, Spin, Result } from "antd";

import { usePaginatedQuery, useMutation, queryCache } from "react-query";

import dayjs from "dayjs";

import { Link, useHistory, generatePath } from "react-router-dom";

import DeleteButton from "../../components/DeleteButton";

const ModulosList = () => {
	const history = useHistory();

	const [query, setQuery] = useQueryParams();

	const page = useMemo(() => Number(query.page || 1), [query]);

	const {
		status,
		resolvedData,
		latestData,
		error,
		isFetching,
		failureCount,
		refetch,
	} = usePaginatedQuery(
		["modulos", page, query.obs, query.data],
		(url, page, obs, data) => http.get(url, { params: { page, obs, data } })
	);

	if (status === "error") {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	const columns = [
		{
			title: "Capa",
			dataIndex: "capa_url",
			render: (capa_url) => {
				return capa_url ? (
					<img
						src={capa_url}
						style={{ width: 80, borderRadius: 4 }}
					/>
				) : (
					"Sem capa"
				);
			},
		},
		{
			title: "Nome",
			dataIndex: "nome",
		},
		{
			title: "Ordem",
			dataIndex: "ordem",
		},
		{
			title: "Premium",
			dataIndex: "premium",
			render(isPremium) {
				return <span>{isPremium ? "sim" : "não"}</span>;
			},
		},

		{
			title: "Ações",
			align: "right",
			key: "action",
			render: (_, record) => (
				<span>
					<Link
						className="mr-5"
						to={generatePath("/modulos/editar/:id", {
							id: record._id,
						})}
					>
						Editar
					</Link>

					<DeleteButton id={record._id} query={"modulos"} />
				</span>
			),
		},
	];

	return (
		<>
			<div className="mb-2"></div>
			<Link to={"/modulos/add"}>
				<Button type="primary" className="mb-2">
					Adicionar
				</Button>
			</Link>

			<Table
				scroll={{ x: "100%" }}
				rowKey={"_id"}
				dataSource={resolvedData ? resolvedData.docs : []}
				pagination={{
					pageSize: 15,
					total: resolvedData ? resolvedData.limit : 0,
					defaultCurrent: page,
					current: page,
					onChange: (newPage) => {
						setQuery({ ...query, page: newPage });
					},
				}}
				columns={columns}
				loading={status === "loading" || isFetching}
			/>
		</>
	);
};

export default ModulosList;
