import React, { useEffect } from "react";

import { hot } from "react-hot-loader/root";

import {
	Link,
	BrowserRouter,
	Switch,
	Route,
	useLocation,
} from "react-router-dom";

import ProLayout from "@ant-design/pro-layout";

import locale from "antd/es/locale/pt_BR";

import { ConfigProvider } from "antd";

import { UserOutlined, LoginOutlined } from "@ant-design/icons";

import { useStoreState, useStoreActions } from "easy-peasy";
import { Layout, Avatar, Menu, Dropdown } from "antd";

import AuthLoading from "./pages/Auth/Loading";
import AuthLogin from "./pages/Auth/Login";

import routes from "./routes";

import "./App.less";

import { ReactQueryConfigProvider } from "react-query";

import MenuSidebar from "./components/MenuSidebar";

const { Content } = Layout;

const RootLayout = () => {
	const user = useStoreState((state) => state.auth.user);

	const signOut = useStoreActions((actions) => actions.auth.signOut);

	const location = useLocation();

	return (
		<ProLayout
			route={{
				routes,
			}}
			title=""
			logo={null}
			style={{ minHeight: "100vh" }}
			rightContentRender={() => (
				<Dropdown
					trigger={["click"]}
					placement="bottomLeft"
					overlay={() => (
						<Menu>
							<Menu.Item>
								<Link
									to={"/usuario"}
									className="items-center flex"
								>
									<UserOutlined />
									<span className="ml-2"> Perfil</span>
								</Link>
							</Menu.Item>
							<Menu.Item onClick={() => signOut()}>
								<LoginOutlined />
								<span className="ml-2"> Sair</span>
							</Menu.Item>
						</Menu>
					)}
				>
					<div
						style={{ padding: "0 32px" }}
						className="hover:bg-gray-300 cursor-pointer "
					>
						<div>
							<Avatar icon={<UserOutlined />} />
							<span className="ml-2">{user.nome}</span>
						</div>
					</div>
				</Dropdown>
			)}
			menuRender={(data) => {
				return <MenuSidebar data={data} />;
			}}
			location={location}
		>
			<Content style={{ margin: "0" }}>
				<div
					className="site-layout-background"
					style={{ padding: " 24px 0", minHeight: 360 }}
				>
					<Switch>
						{routes
							.map((e) => [e, e.children])
							.flat(2)
							.filter((e) => e && e.main)
							.map((route, index) => (
								<Route
									location={location}
									key={index}
									path={route.path}
									exact={route.exact}
									children={<route.main />}
								/>
							))}
					</Switch>
				</div>
			</Content>
		</ProLayout>
	);
};

const queryConfig = {
	// Global
	suspense: false,
	useErrorBoundary: undefined, // Defaults to the value of `suspense` if not defined otherwise
	throwOnError: false,
	refetchAllOnWindowFocus: false,

	// useQuery
	retry: 3,
	retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
	staleTime: 0,
	cacheTime: 5 * 60 * 1000,
	refetchInterval: false,
	refetchOnMount: true,
};

export default hot(() => {
	const authState = useStoreState((state) => state.auth);

	const { checkLogin } = useStoreActions((actions) => actions.auth);

	useEffect(() => {
		checkLogin();
	}, [checkLogin]);

	if (!authState.app_started) {
		return <AuthLoading />;
	}

	return (
		<ConfigProvider locale={locale}>
			<ReactQueryConfigProvider config={queryConfig}>
				<Layout style={{ minHeight: "100vh" }}>
					<BrowserRouter basename={process.env.PUBLIC_URL || "/"}>
						{!authState.authenticated ? (
							<AuthLogin />
						) : (
							<RootLayout />
						)}
					</BrowserRouter>
				</Layout>
			</ReactQueryConfigProvider>
		</ConfigProvider>
	);
});
