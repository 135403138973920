import React, { useMemo, useState, useEffect } from "react";
import http from "@/services/http";

import { useQueryParams } from "@/services/hooks";
import {
	Table,
	Button,
	Spin,
	Result,
	Form,
	Typography,
	notification,
	Popconfirm,
	Card,
	Alert,
} from "antd";

import { usePaginatedQuery, useMutation, queryCache } from "react-query";

import dayjs from "dayjs";

import {
	Link,
	useHistory,
	useRouteMatch,
	useParams,
	generatePath,
} from "react-router-dom";
import DeleteButton from "../../components/DeleteButton";
import SelectAulas from "../../components/Select/SelectAulas";
import { useStoreState, useStoreActions } from "easy-peasy";

//import ChamadosBusca from "./ChamadosBusca";

const PerguntasList = () => {
	const aulaSelected = useStoreState((state) => state.perguntas.aulaSelected);

	const setAulasSelected = useStoreActions(
		(actions) => actions.perguntas.setAulasSelected
	);

	const [query, setQuery] = useQueryParams();

	const page = useMemo(() => Number(query.page || 1), [query]);

	const {
		status,
		resolvedData,
		latestData,
		error,
		isFetching,
		failureCount,
		refetch,
	} = usePaginatedQuery(
		["perguntas", page, query.obs, query.data],
		(url, page, obs, data) => http.get(url, { params: { page, obs, data } })
	);

	if (status === "error") {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	const columns = [
		{
			title: "Enuncionado",
			dataIndex: "enunciado",
			align: "left",
		},
		{
			title: "Pontos",
			dataIndex: "pontos",
			align: "left",
		},

		{
			title: "Ações",
			key: "action",
			align: "right",
			render: (_, record) => (
				<span>
					<Link
						className="mr-5"
						to={generatePath("/exercicios/editar/:id", {
							id: record._id,
						})}
					>
						Editar
					</Link>

					<DeleteButton id={record._id} query={"perguntas"} />
				</span>
			),
		},
	];

	return (
		<>
			<Card title="Selecione um aula">
				{!aulaSelected?.value && (
					<Alert
						type={"warning"}
						className={"mb-5"}
						message={
							"Para adicionar uma pergunta você tem que selecionar uma aula"
						}
					/>
				)}

				<Form>
					<Form.Item shouldUpdate={false} label={"Aula"}>
						<SelectAulas
							onChange={(data) => {
								setAulasSelected(data);
							}}
							value={aulaSelected}
						/>
					</Form.Item>
				</Form>
			</Card>

			<div className="mb-2"></div>
			<Button
				type="primary"
				className="mb-2"
				disabled={!aulaSelected?.value}
			>
				<Link to={"/exercicios/add"}>Adicionar</Link>
			</Button>

			<Table
				scroll={{ x: "100%" }}
				rowKey={"_id"}
				dataSource={resolvedData ? resolvedData.docs : []}
				pagination={{
					pageSize: 15,
					total: resolvedData ? resolvedData.limit : 0,
					defaultCurrent: page,
					current: page,
					onChange: (newPage) => {
						setQuery({ ...query, page: newPage });
					},
				}}
				columns={columns}
				loading={status === "loading" || isFetching}
			/>
		</>
	);
};

export default PerguntasList;
