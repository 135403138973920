import React, { useMemo } from "react";
import http from "@/services/http";

import { useQueryParams } from "@/services/hooks";
import {
	Table,
	Button,
	Spin,
	Result,
	Typography,
	notification,
	Popconfirm,
} from "antd";

import { usePaginatedQuery, useMutation, queryCache } from "react-query";

import dayjs from "dayjs";

import {
	Link,
	useHistory,
	useRouteMatch,
	useParams,
	generatePath,
} from "react-router-dom";
import DeleteButton from "../../components/DeleteButton";
//import ChamadosBusca from "./ChamadosBusca";

const UsuariosList = () => {
	const history = useHistory();

	const [query, setQuery] = useQueryParams();

	const page = useMemo(() => Number(query.page || 1), [query]);

	const {
		status,
		resolvedData,
		latestData,
		error,
		isFetching,
		failureCount,
		refetch,
	} = usePaginatedQuery(
		["usuarios", page, query.obs, query.data],
		(url, page, obs, data) => http.get(url, { params: { page, obs, data } })
	);

	if (status === "error") {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	const columns = [
		{
			title: "Avatar",
			dataIndex: "foto_url",
			render(foto_url) {
				return foto_url ? (
					<img
						src={foto_url}
						style={{ width: 50, height: 50, borderRadius: 100 }}
					/>
				) : (
					"Sem capa"
				);
			},
		},
		{
			title: "Nome",
			dataIndex: "nome",
		},
		{
			title: "Email",
			dataIndex: "email",
		},

		{
			title: "Admin",
			dataIndex: "is_admin",
			render(is_admin) {
				return is_admin
					? "Perfil Adminstrativo"
					: "Perfil não administrativo";
			},
		},
		{
			title: "Pontos",
			dataIndex: "points",
			render(points) {
				return <b>{points || 0}</b>;
			},
		},
		{
			title: "Ações",
			key: "action",
			render: (_, record) => (
				<span>
					<Link
						className="mr-5"
						to={generatePath("/usuarios/editar/:id", {
							id: record.id,
						})}
					>
						Editar
					</Link>

					<DeleteButton id={record.id} query={"usuarios"} />
				</span>
			),
		},
	];

	return (
		<>
			<div className="mb-2">
				{/* <ChamadosBusca
					onReset={() => {
						setQuery({ data: "", obs: "" });
					}}
					onFinish={(values) => {
						setQuery({
							obs: values.obs,
							data: values.data[0]
								? [
										dayjs(values.data[0]).format(
											"YYYY-MM-DD"
										),
										dayjs(values.data[1]).format(
											"YYYY-MM-DD"
										),
								  ]
								: "",
						});
					}}
					data={{
						data: query.data
							? [
									dayjs(query.data[0], "YYYY-MM-DD"),
									dayjs(query.data[1], "YYYY-MM-DD"),
							  ]
							: "",
						obs: query.obs,
					}}
				/> */}
			</div>
			<Link to={"/usuarios/add"}>
				<Button type="primary" className="mb-2">
					Adicionar
				</Button>
			</Link>

			<Table
				scroll={{ x: "100%" }}
				rowKey={"id"}
				dataSource={resolvedData ? resolvedData.docs : []}
				pagination={{
					pageSize: 15,
					total: resolvedData ? resolvedData.totalDocs : 0,
					defaultCurrent: page,
					current: page,
					onChange: (newPage) => {
						setQuery({ ...query, page: newPage });
					},
				}}
				columns={columns}
				loading={status === "loading" || isFetching}
			/>
		</>
	);
};

export default UsuariosList;
