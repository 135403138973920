import React, { useState } from "react";

import http from "@/services/http";
import dayjs from "dayjs";
import {
	Tabs,
	Form,
	Card,
	Input,
	notification,
	Button,
	InputNumber,
	Row,
	Col,
	DatePicker,
	Select,
	Switch,
} from "antd";
import Upload from "../../components/Upload";

import { useMutation, queryCache } from "react-query";
import { useRouteMatch, useHistory, generatePath } from "react-router-dom";

const ModulosAdd = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const onFinish = async (data) => {
		try {
			setLoading(true);

			await http.post("modulos", data);

			await queryCache.refetchQueries(["modulos"]);

			notification.open({
				message: "Módulo cadastrado com sucesso",
				type: "success",
			});

			history.push("/modulos");

			form.resetFields();
		} catch (error) {
			notification.open({
				message: "Erro inesperado ao cadastrar o Módulo",
				type: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			initialValues={{ nome: "", premium: true, ordem: null }}
		>
			<Card title="Cadastrar módulo">
				<Row gutter={24}>
					<Col span={18}>
						<Form.Item
							name="nome"
							label="Nome"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input
								placeholder={"Digite o nome do seu modulo"}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Ordem" name="ordem">
							<InputNumber
								style={{ width: "100%" }}
								//placeholder={"Digite um email válido"}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={18}>
						<Form.Item name="capa_url" label={"Capa"}>
							<Input placeholder="Url da imagem" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="premium"
							label={"Premium"}
							help="Coloca o modulo como premiun"
						>
							<Switch defaultChecked={true} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item shouldUpdate={false}>
							<Upload
								accept="image/*"
								onChange={(capa_url) => {
									form.setFieldsValue({ capa_url });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Salvar
					</Button>
					<Button
						className="ml-2"
						disabled={loading}
						onClick={() => history.goBack()}
						type="default"
						htmlType="button"
					>
						Voltar
					</Button>
				</Form.Item>
			</Card>
		</Form>
	);
};

export default ModulosAdd;
