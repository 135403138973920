import React from "react";
import ReactDOM from "react-dom";

import { ConfigProvider } from "antd";

import App from "./App";
import "@/assets/css/style.css";
import * as serviceWorker from "./serviceWorker";

import { StoreProvider } from "easy-peasy";
import store from "./store";

import dayjs from "dayjs";
import locale from "dayjs/locale/pt-br";

dayjs.locale(locale);

ReactDOM.render(
	<StoreProvider store={store}>
		<App />
	</StoreProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
