import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";
import qs from "query-string";

export const useQueryParams = () => {
	const location = useLocation();

	const history = useHistory();

	const query = useMemo(() => {
		return qs.parse(location.search);
	}, [location.search]);

	const setQuery = useCallback(
		(values) => {
			const query = qs.stringify(values);

			history.push({
				pathname: location.pathname,
				search: "?" + query.toString(),
			});
		},
		[history, location.pathname]
	);
	const clearQuery = useCallback(() => {
		history.push({
			pathname: location.pathname,
			search: "",
		});
	}, [history, location.pathname]);

	return [query, setQuery, clearQuery];
};
