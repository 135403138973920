import React, { useState } from "react";

import http from "@/services/http";
import dayjs from "dayjs";
import {
	Tabs,
	Form,
	Card,
	Input,
	notification,
	Button,
	InputNumber,
	Row,
	Col,
	DatePicker,
	Select,
} from "antd";

import Upload from "../../components/Upload";
import SelectModulos from "../../components/Select/SelectModulos";

import { useMutation, queryCache } from "react-query";
import { useRouteMatch, useHistory, generatePath } from "react-router-dom";
import FormProva from "./FormProva";
const { Option } = Select;
const { TabPane } = Tabs;

const AulasAdd = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const onFinish = async ({ modulo, ...data }) => {
		try {
			setLoading(true);

			await http.post("aulas", { modulo: modulo.value, ...data });

			await queryCache.refetchQueries(["aulas"]);

			notification.open({
				message: "Aulas cadastrado com sucesso",
				type: "success",
			});

			history.push("/aulas");

			form.resetFields();
		} catch (error) {
			notification.open({
				message: "Erro inesperado ao cadastrar o Aulas",
				type: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			initialValues={{ nome: "", video_url: "", modulo: undefined }}
		>
			<Card title="Cadastrar aula">
				<Row gutter={24}>
					<Col span={14}>
						<Form.Item
							name="nome"
							label="Nome"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							label="Modulo"
							name="modulo"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<SelectModulos />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Ordem" name="ordem">
							<InputNumber
								style={{ width: "100%" }}
								//placeholder={"Digite um email válido"}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Form.Item
							name="video_url"
							label={"Video"}
							placeholder="Url do vídeo"
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item shouldUpdate={false}>
							<Upload
								onChange={(video_url) => {
									form.setFieldsValue({ video_url });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Salvar
					</Button>
					<Button
						className="ml-2"
						disabled={loading}
						onClick={() => history.goBack()}
						type="default"
						htmlType="button"
					>
						Voltar
					</Button>
				</Form.Item>
			</Card>
		</Form>
	);
};

export default AulasAdd;
