import React, { useMemo } from "react";
import http from "@/services/http";

import { useQueryParams } from "@/services/hooks";
import { Table, Button, Result } from "antd";
import { usePaginatedQuery } from "react-query";

import dayjs from "dayjs";

import { Link, useHistory, generatePath } from "react-router-dom";
import DeleteButton from "../../components/DeleteButton";
//import ChamadosBusca from "./ChamadosBusca";

const TurmasList = () => {
	const history = useHistory();

	const [query, setQuery] = useQueryParams();

	const page = useMemo(() => Number(query.page || 1), [query]);

	const {
		status,
		resolvedData,
		latestData,
		error,
		isFetching,
		failureCount,
		refetch,
	} = usePaginatedQuery(
		["turmas", page, query.obs, query.data],
		(url, page, obs, data) => http.get(url, { params: { page, obs, data } })
	);

	if (status === "error") {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	const columns = [
		{
			title: "Nome",
			dataIndex: "nome",
			align: "left",
		},
		{
			title: "Data Inscrição",
			key: "data_inscricao",
			align: "left",
			render(_, record) {
				return `${
					record.data_inscricao_inicial
						? dayjs(record.data_inscricao_inicial).format(
								"DD/MM/YYYY"
						  )
						: "__/__/____"
				} - ${
					record.data_inscricao_final
						? dayjs(record.data_inscricao_final).format(
								"DD/MM/YYYY"
						  )
						: "__/__/____"
				}`;
			},
		},
		{
			title: "Fechamento da Turma",
			key: "data_final",
			align: "left",
			render({ data_final }) {
				return `${
					data_final
						? dayjs(data_final).format("DD/MM/YYYY")
						: "__/__/____"
				}`;
			},
		},

		{
			title: "Ações",
			key: "action",
			align: "right",
			render: (_, record) => (
				<span>
					<Link
						className="mr-5"
						to={generatePath("/turmas/editar/:id", {
							id: record._id,
						})}
					>
						Editar
					</Link>

					<DeleteButton id={record._id} query={"turmas"} />
				</span>
			),
		},
	];

	return (
		<>
			<div className="mb-2"></div>
			<Link to={"/turmas/add"}>
				<Button type="primary" className="mb-2">
					Adicionar
				</Button>
			</Link>

			<Table
				scroll={{ x: "100%" }}
				rowKey={"id"}
				dataSource={resolvedData ? resolvedData.docs : []}
				pagination={{
					pageSize: 15,
					total: resolvedData ? resolvedData.limit : 0,
					defaultCurrent: page,
					current: page,
					// onChange: (newPage) => {
					// 	setQuery({ ...query, page: newPage });
					// },
				}}
				columns={columns}
				loading={status === "loading" || isFetching}
			/>
		</>
	);
};

export default TurmasList;
