import React, { useEffect, useCallback, useState } from "react";

import { Form, Input, Collapse, Button, Col, Row, Card, Checkbox } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
	CheckCircleOutlined,
	DeleteFilled,
	CheckCircleFilled,
} from "@ant-design/icons";
import { v4 as uuid } from "uuid";

import "./styles.css";
//import Upload from "../../components/Upload";

const { Panel } = Collapse;

const PanelFormOpcoes = ({ form, remove, index, field }) => {
	const pergunta = form.getFieldsValue();

	const opcao = pergunta.opcoes[field.fieldKey];

	const isCheck = pergunta.opcao_correta_id === opcao?.id;

	const [resposta, setResposta] = useState("");

	const handleClickOpcao = (index) => (e) => {
		e.stopPropagation();

		const pergunta = form.getFieldsValue();

		const opcao_correta_id = pergunta.opcoes[index].id;

		form.setFieldsValue({
			opcao_correta_id,
		});
	};
	return (
		<Collapse>
			<Panel
				header={
					<>
						<b
							className={`ml-2 ${
								isCheck ? "text-green-600" : "text-red-600"
							}`}
						>
							#{index + 1} Opção{" "}
							{isCheck ? "correta" : "incorreta"}:{" "}
						</b>
						<span
							className={`px-2
								${isCheck ? "text-green-600" : "text-red-600"}`}
							style={{
								wordBreak: "break-word",
							}}
						>
							{resposta || ""}
						</span>
					</>
				}
				key={field.key}
				extra={
					<>
						{!isCheck ? (
							<Button
								className="mr-5 mb-2"
								type={"primary"}
								icon={<CheckCircleOutlined />}
								onClick={handleClickOpcao(field.fieldKey)}
							/>
						) : null}
						<Button
							type="primary"
							icon={<DeleteFilled />}
							danger
							onClick={(e) => {
								e.stopPropagation();

								remove(field.name);
							}}
						/>
					</>
				}
			>
				<Row gutter={24}>
					<Col span={24}>
						<Form.Item
							name={[field.name, "resposta"]}
							fieldKey={[field.fieldKey, "resposta"]}
							rules={[
								{
									required: true,
									message: "A resposta é obrigatória",
								},
							]}
						>
							<Input.TextArea
								placeholder="Resposta"
								onChange={(e) => setResposta(e.target.value)}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Panel>
		</Collapse>
	);
};

const FormOpcoes = ({ form }) => {
	const pergunta = form.getFieldsValue();

	return (
		<Form.List name={"opcoes"}>
			{(fields, { add, remove }) => (
				<div>
					{fields.map((field, index) => {
						return (
							<PanelFormOpcoes
								index={index}
								key={field.key}
								field={field}
								remove={remove}
								form={form}
							/>
						);
					})}

					<Form.Item>
						<Button
							className="w-full mt-5"
							type="dashed"
							onClick={() => {
								add({
									id: uuid(),
									resposta: "",
								});
							}}
						>
							<PlusOutlined /> Adicionar Opção
						</Button>
					</Form.Item>
				</div>
			)}
		</Form.List>
	);
};

export default FormOpcoes;
