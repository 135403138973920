import React, { useEffect, useState } from "react";

import http from "@/services/http";
import dayjs from "dayjs";
import {
	Tabs,
	Form,
	Card,
	Input,
	notification,
	Button,
	InputNumber,
	Row,
	Col,
	DatePicker,
	Select,
	Spin,
	Result,
} from "antd";

import Upload from "../../components/Upload";

import { useMutation, queryCache, useQuery } from "react-query";
import {
	useRouteMatch,
	useParams,
	useHistory,
	generatePath,
} from "react-router-dom";
import FormOpcoes from "./FormOpcoes";
import { useStoreState } from "easy-peasy";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const { TabPane } = Tabs;

const PerguntaEdit = () => {
	const history = useHistory();

	const params = useParams();

	const URL = `/perguntas/${params.id}`;

	const { data, refetch, error, isFetching, status } = useQuery(URL, (url) =>
		http.get(url)
	);

	const [loading, setLoading] = useState(false);

	const aulaSelected = useStoreState((state) => state.perguntas.aulaSelected);

	const [form] = Form.useForm();

	useEffect(() => {
		if (data) {
			form.setFieldsValue({
				enunciado: data.enunciado,
				opcoes: data.opcoes,
				pontos: data.pontos,
				opcao_correta_id: data.opcao_correta_id,
			});
		}
	}, [data]);

	const onFinish = async (data) => {
		try {
			setLoading(true);

			if (!data.opcao_correta_id) {
				notification.open({
					message: "A pergunta precisa de uma opção correta",
					type: "success",
				});
				return false;
			}

			await http.put(URL, {
				...data,
				aula_id: params.id,
			});

			await queryCache.refetchQueries(["perguntas"]);

			notification.open({
				message: "Exercícios alterado com sucesso",
				type: "success",
			});

			history.push("/exercicios");

			form.resetFields();
		} catch (error) {
			notification.open({
				message: "Erro inesperado ao cadastrar o pergunta",
				type: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	if (error) {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	if (status === "loading" || !data) {
		return (
			<div
				style={{ minHeight: "60vh", width: "100%" }}
				className="flex items-center justify-center"
			>
				<Spin size={20} />
			</div>
		);
	}

	return (
		<Form form={form} onFinish={onFinish} layout="vertical">
			<Card title="Editar exercício">
				<Form.Item name="opcao_correta_id" noStyle>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item name="opcoes" noStyle>
					<Input type="hidden" />
				</Form.Item>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Geral">
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									name="enunciado"
									label="Enunciado"
									rules={[
										{
											required: true,
											message: "Campo obrigatório",
										},
									]}
								>
									<TextArea rows={4} />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={18}>
								<Form.Item
									name="arquivo_url"
									label={"Audio ou Imagem"}
								>
									<Input placeholder="Url do audio/imagem" />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name="pontos" label={"Pontos"}>
									<InputNumber style={{ width: "100%" }} />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item shouldUpdate={false}>
									<Upload
										onChange={(arquivo_url) => {
											form.setFieldsValue({
												arquivo_url,
											});
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
					</TabPane>
					<TabPane tab="Questionário" key="2">
						<FormOpcoes form={form} />
					</TabPane>
				</Tabs>

				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Salvar
					</Button>
					<Button
						className="ml-2"
						disabled={loading}
						onClick={() => history.goBack()}
						type="default"
						htmlType="button"
					>
						Voltar
					</Button>
				</Form.Item>
			</Card>
		</Form>
	);
};

export default PerguntaEdit;
