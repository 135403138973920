import { thunk, action } from "easy-peasy";

import http from "@/services/http";

import jwt_decode from "jwt-decode";

export default {
	user: {},
	app_started: false,
	authenticated: false,

	setUser: action((state, user) => {
		state.user = user;
	}),
	loginSuccess: action(state => {
		state.authenticated = true;
	}),
	loggout: action(state => {
		state.authenticated = false;
	}),
	appLoading: action(state => {
		state.app_started = false;
	}),
	appLoaded: action(state => {
		state.app_started = true;
	}),
	signOut: thunk(async actions => {
		localStorage.removeItem("token");
		actions.loggout();
		actions.setUser({});
	}),
	login: thunk(async (actions, { username, password }) => {
		const { token } = await http.post("/auth/login", {
			username,
			password
		});

		localStorage.setItem("token", token);

		await actions.checkLogin(token);
	}),
	checkLogin: thunk(async actions => {
		try {
			actions.appLoading();

			const token = localStorage.getItem("token");

			if (!token) return false;

			const user = jwt_decode(token);

			if (user) {
				actions.setUser(user);
				actions.loginSuccess();
			}
		} catch (error) {
			console.log("errror", error);
			await actions.signOut();
		} finally {
			actions.appLoaded();
		}
	})
};
