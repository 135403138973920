import React, { useState } from "react";

import http from "@/services/http";

import {
	Form,
	Card,
	Input,
	notification,
	Button,
	Row,
	Col,
	Select,
	Result,
	Spin,
	InputNumber,
} from "antd";

import Upload from "@/components/Upload";
import SelectModulos from "@/components/Select/SelectModulos";

import { queryCache, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

const UsuariosEdit = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const params = useParams();

	const { data, isLoading, isFetching, refetch, error } = useQuery(
		"aulas/" + params.id,
		(url) => http.get(url)
	);

	const [form] = Form.useForm();

	const onFinish = async ({ modulo, ...data }) => {
		try {
			setLoading(true);

			await http.put("aulas/" + params.id, {
				...data,
				modulo: modulo.value,
			});

			await queryCache.refetchQueries(["aulas"]);
			await queryCache.refetchQueries(["aulas/" + params.id]);

			notification.open({
				message: "Aula atualizada com sucesso",
				type: "success",
			});

			history.push("/aulas");

			form.resetFields();
		} catch (error) {
			notification.open({
				message: "Erro inesperado ao atualizar a aula",
				type: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	if (error) {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	if (isLoading || !data) {
		return (
			<div
				style={{ minHeight: "60vh", width: "100%" }}
				className="flex items-center justify-center"
			>
				<Spin size={20} />
			</div>
		);
	}

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			initialValues={{
				nome: data.nome,
				video_url: data.video_url,
				ordem: data.ordem,
				modulo: {
					label: data?.modulo?.nome,
					value: data?.modulo?._id,
				},
			}}
		>
			<Card title="Editar aula">
				<Row gutter={24}>
					<Col span={14}>
						<Form.Item
							name="nome"
							label="Nome"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							label="Modulo"
							name="modulo"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<SelectModulos />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Ordem" name="ordem">
							<InputNumber
								style={{ width: "100%" }}
								//placeholder={"Digite um email válido"}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Form.Item
							name="video_url"
							label={"Video"}
							placeholder="Url do vídeo"
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item shouldUpdate={false}>
							<Upload
								onChange={(video_url) => {
									form.setFieldsValue({ video_url });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Salvar
					</Button>
					<Button
						className="ml-2"
						disabled={loading}
						onClick={() => history.goBack()}
						type="default"
						htmlType="button"
					>
						Voltar
					</Button>
				</Form.Item>
			</Card>
		</Form>
	);
};

export default UsuariosEdit;
