import React, { useState } from "react";

import http from "@/services/http";

import {
	Form,
	Card,
	DatePicker,
	Input,
	notification,
	Button,
	Row,
	Col,
	Spin,
	Result,
} from "antd";

import dayjs from "dayjs";

import { queryCache, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

const TurmaEdit = () => {
	const history = useHistory();

	const params = useParams();

	const URL = `/turmas/${params.id}`;

	const { data, isFetching, refetch, error, status } = useQuery(URL, (url) =>
		http.get(url)
	);

	console.log("data", data);

	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const onFinish = async ({
		data_final,
		data_inscricao_final,
		data_inscricao_inicial,
		nome,
	}) => {
		try {
			setLoading(true);

			await http.put(URL, {
				data_final: dayjs(data_final).format("YYYY-MM-DD"),
				data_inscricao_final: dayjs(data_inscricao_final).format(
					"YYYY-MM-DD"
				),
				data_inscricao_inicial: dayjs(data_inscricao_inicial).format(
					"YYYY-MM-DD"
				),
				nome,
			});

			await queryCache.refetchQueries(["turmas"]);

			notification.open({
				message: "Turma alterada com sucesso",
				type: "success",
			});

			history.push("/turmas");

			form.resetFields();
		} catch (error) {
			notification.open({
				message: "Erro inesperado ao cadastrar o turmas",
				type: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	if (error) {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	if (status === "loading" || !data) {
		return (
			<div
				style={{ minHeight: "60vh", width: "100%" }}
				className="flex items-center justify-center"
			>
				<Spin size={20} />
			</div>
		);
	}

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			initialValues={{
				nome: data.nome,
				data_inscricao_inicial: dayjs(data.data_inscricao_inicial),
				data_inscricao_final: dayjs(data.data_inscricao_final),
				data_final: dayjs(data.data_final),
			}}
		>
			<Card title="Alterar turma">
				<Row gutter={24}>
					<Col span={24}>
						<Form.Item
							name="nome"
							label="Nome"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input placeholder="Identificador da turma" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={8}>
						<Form.Item
							label="Data inicial da inscrição"
							name="data_inscricao_inicial"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<DatePicker
								format="DD/MM/YYYY"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Data final da inscrição"
							name="data_inscricao_final"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<DatePicker
								format="DD/MM/YYYY"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="data_final"
							label={"Data final"}
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<DatePicker
								style={{ width: "100%" }}
								format="DD/MM/YYYY"
								placeholder="Selecione a data final"
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Salvar
					</Button>
					<Button
						className="ml-2"
						disabled={loading}
						onClick={() => history.goBack()}
						type="default"
						htmlType="button"
					>
						Voltar
					</Button>
				</Form.Item>
			</Card>
		</Form>
	);
};

export default TurmaEdit;
