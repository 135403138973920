import React from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import http from "../../services/http";

const { Option } = Select;

class SelectModulos extends React.Component {
	constructor(props) {
		super(props);
		this.lastFetchId = 0;
		this.fetch = debounce(this.fetch, 800);
		this.ref = React.createRef();
	}

	componentDidMount() {
		if (!this.data) {
			this.fetch();
		}
	}

	state = {
		data: [],
		fetching: false,
	};

	fetch = (value) => {
		this.lastFetchId += 1;
		const fetchId = this.lastFetchId;
		this.setState({ data: [], fetching: true });
		http.get("/modulos/", {
			params: {
				busca_nome: value,
			},
		})
			.then((body) => {
				if (fetchId !== this.lastFetchId) {
					// for fetch callback order
					return;
				}

				const data = body.docs.map((item) => ({
					label: item.nome,
					value: item._id,
				}));

				this.setState({ data, fetching: false });
			})
			.catch((e) => {
				console.log("e", e);
			});
	};

	handleChange = (item) => {
		this.setState({ fetching: false });

		this.props.onChange &&
			this.props.onChange(
				item ? { value: item.value, label: item.label } : undefined
			);
	};

	render() {
		const { fetching, data } = this.state;
		const { onChange, onSelect, ...props } = { ...this.props };

		return (
			<Select
				{...props}
				ref={this.ref}
				showSearch
				allowClear
				labelInValue
				placeholder="Digite para achar mais Modulos"
				notFoundContent={fetching ? <Spin size="small" /> : null}
				filterOption={false}
				onSearch={this.fetch}
				onChange={this.handleChange}
				style={{ width: "100%" }}
			>
				{data.map((d) => (
					<Option key={d.value} value={d.value}>
						{d.label}
					</Option>
				))}
			</Select>
		);
	}
}

export default SelectModulos;
