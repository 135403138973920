import React, { useState } from "react";
import { Descriptions, Form, Card, Input, Modal, notification } from "antd";
import { useStoreState } from "easy-peasy";
import Button from "antd/es/button/button";
import http from "../../services/http";

const UserDetails = () => {
	const user = useStoreState((state) => state.auth.user);

	const [changePassword, setChangePassword] = useState(false);

	const [form] = Form.useForm();

	const [loading, setLoading] = useState(false);

	const onChangePassword = async ({ current_password, new_password }) => {
		try {
			setLoading(true);

			await http.put("/user/change-password", {
				current_password,
				new_password,
			});

			notification.open({
				message: "Sua nova senha foi salva com sucesso",
				type: "success",
			});

			setChangePassword(false);

			form.resetFields();
		} catch (error) {
			notification.open({
				message:
					"Erro ao mudar sua senha verifique se você digitou a senha corretamente",
				type: "error",
			});
			return false;
		} finally {
			setLoading(false);
		}
	};

	return (
		<Card>
			<Descriptions title="Informações do Usuário">
				<Descriptions.Item label="Usuário">
					{user.nome}
				</Descriptions.Item>
				<Descriptions.Item label="Perfil">
					{user.perfil ? user.perfil : "SEM"}
				</Descriptions.Item>
				<Descriptions.Item label="E-mail">
					{user.email}
				</Descriptions.Item>
				<Descriptions.Item label="Senha">
					<Button onClick={() => setChangePassword(true)}>
						Mudar senha
					</Button>
				</Descriptions.Item>
			</Descriptions>
			<Modal
				title="Mudar senha"
				cancelText="Cancelar"
				confirmLoading={loading}
				visible={changePassword}
				onOk={() => form.submit()}
				onCancel={() => {
					if (!loading) setChangePassword(false);
				}}
			>
				<Form form={form} onFinish={onChangePassword}>
					<Form.Item
						label="Senha atual"
						name="current_password"
						rules={[
							{
								required: true,
								message: "Por favor digite sua senha",
							},
						]}
						hasFeedback
					>
						<Input.Password placeholder="**********" />
					</Form.Item>
					<Form.Item
						label="Nova senha"
						name="new_password"
						rules={[
							{
								required: true,
								message: "Por favor digite sua nova senha",
							},
						]}
						hasFeedback
					>
						<Input.Password placeholder="**********" />
					</Form.Item>
					<Form.Item
						label="Confirmar senha"
						name="confirmation_passoword"
						rules={[
							{
								required: true,
								message: "Por favor confirme sua senha!",
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (
										!value ||
										getFieldValue("new_password") === value
									) {
										return Promise.resolve();
									}
									return Promise.reject(
										"As senhas precisa ser iguais"
									);
								},
							}),
						]}
						hasFeedback
					>
						<Input.Password placeholder="**********" />
					</Form.Item>
				</Form>
			</Modal>
		</Card>
	);
};

export default UserDetails;
