import React, { useState } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { API_URL } = process.env;

const AvatarUpload = (props) => {
	const [loading, setLoading] = useState(false);

	const AUTH_TOKEN = localStorage.getItem("token");


	const handleChange = (info) => {
		const { status, response } = info.file;

		if (status === "uploading") {
			setLoading(true);
			return;
		}

		if (status === "done") {
			// Get this url from response in real world.

			const url = response.url;
			setLoading(false);

			props.onChange(url);

			message.success(`Upload com sucesso .`);
		} else if (status === "error") {
			message.error(`Erro ao fazer upload desse arquivo.`);
		}
	};

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	return (
		<Upload
			name="file"
			listType="picture-card"
			className="avatar-uploader"
			showUploadList={false}
			headers={{
				Authorization: `Bearer ${AUTH_TOKEN}`,
			}}
			action={`${API_URL}/upload/`}
			onChange={handleChange}
		>
			{props.value ? (
				<img
					src={props.value}
					alt="avatar"
					style={{
						width: "100%",
						height: "100%",
						objectFit: "cover",
					}}
				/>
			) : (
				uploadButton
			)}
		</Upload>
	);
};

export default AvatarUpload;
