import axios from "axios";
import store from "../store";
import qs from "qs";
const { API_URL } = process.env;

const http = axios.create({
	baseURL: API_URL,
	//paramsSerializer: (p) => qs.stringify(p),
});

http.interceptors.request.use(async (config) => {
	const AUTH_TOKEN = localStorage.getItem("token");

	if (AUTH_TOKEN) config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;

	return config;
});

http.interceptors.response.use(
	(response) => {
		//console.log("response:", response);
		return response.data;
	},
	(err) => {
		if (err && err.response && err.response.status === 401) {
			store.getActions().auth.signOut();
		}

		return Promise.reject(err);
	}
);

export default http;
