import React, { useState } from "react";

import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import "./login.css";

import styled from "styled-components";

import { useStoreActions } from "easy-peasy";

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/28963/form-bk.jpg")
		no-repeat center center;
	background-size: cover;
`;

const Center = styled.div`
	border-radius: 4px;
	padding: 20px;
	max-width: 450px;
	background: white;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NormalLoginForm = () => {
	const login = useStoreActions((actions) => actions.auth.login);

	const [loading, setLoading] = useState(false);

	const onFinish = async (values) => {
		try {
			setLoading(true);
			await login(values);
		} catch (error) {
			if (
				error &&
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				notification.open({
					message: error.response.data.message,
					type: "error",
				});
			} else {
				notification.open({
					message:
						"Aconteceu um erro desconhecido, por favor tente novamente",
					type: "error",
				});
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container>
			<Center>
				<img
					src={require("./../../assets/img/Ingles_Na_Vida_Completo.svg")}
					style={{ maxWidth: 250, margin: "30px 0 " }}
				/>
				<Form
					name="normal_login"
					style={{ minWidth: 300 }}
					initialValues={{ remember: true }}
					onFinish={onFinish}
				>
					<Form.Item
						name="username"
						rules={[
							{
								required: true,
								message: "O usuário é obrigatório",
							},
						]}
					>
						<Input
							prefix={
								<UserOutlined className="site-form-item-icon" />
							}
							placeholder="Username"
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "A senha é obrigatório",
							},
						]}
					>
						<Input
							prefix={
								<LockOutlined className="site-form-item-icon" />
							}
							type="password"
							placeholder="Password"
						/>
					</Form.Item>
					{/* <Form.Item>
					<Form.Item name="remember" valuePropName="checked" noStyle>
						<Checkbox>Remember me</Checkbox>
					</Form.Item>

					<a className="login-form-forgot" href="#">
						Forgot password
					</a>
				</Form.Item> */}

					<Form.Item>
						<Button
							block
							type="primary"
							htmlType="submit"
							loading={loading}
						>
							Entrar
						</Button>
					</Form.Item>
				</Form>
			</Center>
		</Container>
	);
};

export default NormalLoginForm;
