import React from "react";
import { Popconfirm, Spin, notification, Button } from "antd";
import { queryCache, useMutation } from "react-query";
import http from "../services/http";

const DeleteButton = React.memo(({ id, query }) => {
	const [mutate, { status }] = useMutation(
		() => http.delete(query + "/" + id),
		{
			onSettled: () => queryCache.refetchQueries(query)
		}
	);

	if (status == "loading") {
		return <Spin size={12} />;
	}

	async function handleDelete() {
		try {
			await mutate();

			notification.success({ message: "Item excluido com sucesso" });
		} catch (error) {
			notification.error({ message: "Erro ao deletar item" });
		}
	}

	return (
		<Popconfirm
			title="Você tem certeza que deseja excluir este item ?"
			okText="Sim"
			cancelText="Não"
			onConfirm={handleDelete}
		>
			<Button type="link">Excluir</Button>
		</Popconfirm>
	);
});

export default DeleteButton;
