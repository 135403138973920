import React, { useState } from "react";

import http from "@/services/http";

import {
	Form,
	Card,
	Input,
	notification,
	Button,
	InputNumber,
	Row,
	Col,
	Select,
	Result,
	Spin,
	Switch,
} from "antd";

import Upload from "../../components/Upload";

import { queryCache, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

const ModulosEdit = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const params = useParams();

	const { data, isLoading, isFetching, refetch, error } = useQuery(
		"modulos/" + params.id,
		(url) => http.get(url)
	);

	const [form] = Form.useForm();

	const onFinish = async (data) => {
		try {
			setLoading(true);

			await http.put(`/modulos/${params.id}`, { ...data });

			await queryCache.refetchQueries(["modulos"]);
			await queryCache.refetchQueries(["modulos/" + params.id]);

			notification.open({
				message: "Modulo atualizado com sucesso",
				type: "success",
			});

			history.push("/modulos");

			form.resetFields();
		} catch (error) {
			notification.open({
				message: "Erro inesperado ao atualizar o Modulo",
				type: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	if (error) {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	if (isLoading || !data) {
		return (
			<div
				style={{ minHeight: "60vh", width: "100%" }}
				className="flex items-center justify-center"
			>
				<Spin size={20} />
			</div>
		);
	}

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			initialValues={{
				nome: data.nome,
				ordem: data.ordem,
				capa_url: data.capa_url,
				premium: data.premium,
			}}
		>
			<Card title="Cadastrar módulo">
				<Row gutter={24}>
					<Col span={18}>
						<Form.Item
							name="nome"
							label="Nome"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input
								placeholder={"Digite o nome do seu modulo"}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Ordem" name="ordem">
							<InputNumber
								style={{ width: "100%" }}
								//placeholder={"Digite um email válido"}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={18}>
						<Form.Item name="capa_url" label={"Capa"}>
							<Input placeholder="Url da imagem" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="premium"
							label={"Premium"}
							help="Coloca o modulo como premiun"
						>
							<Switch defaultChecked={data.premium} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item shouldUpdate={false}>
							<Upload
								accept="image/*"
								onChange={(capa_url) => {
									form.setFieldsValue({ capa_url });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Salvar
					</Button>
					<Button
						className="ml-2"
						disabled={loading}
						onClick={() => history.goBack()}
						type="default"
						htmlType="button"
					>
						Voltar
					</Button>
				</Form.Item>
			</Card>
		</Form>
	);
};

export default ModulosEdit;
