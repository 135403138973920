import { createStore } from "easy-peasy";

import auth from "./auth";
import theme from "./theme";
import perguntas from "./perguntas";
import dashboard from "./dashboard";

export default createStore({
	theme,
	perguntas,
	dashboard,
	auth,
});
