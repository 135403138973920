import React from "react";

//import Dashboard from "./pages/Dashboard";

import UserDetails from "./pages/User/UserDetails";

import {
	UserOutlined,
	AreaChartOutlined,
	PlusOutlined,
	OrderedListOutlined,
	ProfileOutlined,
	ReadOutlined,
	SolutionOutlined,
	PlaySquareOutlined,
	TeamOutlined,
} from "@ant-design/icons";

import UsuariosList from "./pages/Usuarios/UsuariosList";
import UsuariosAdd from "./pages/Usuarios/UsuariosAdd";
import UsuariosEdit from "./pages/Usuarios/UsuariosEdit";

import AulasList from "./pages/Aulas/AulasList";
import AulasAdd from "./pages/Aulas/AulasAdd";
import AulasEdit from "./pages/Aulas/AulasEdit";

import ModulosList from "./pages/Modulos/ModulosList";
import ModulosAdd from "./pages/Modulos/ModulosAdd";
import ModulosEdit from "./pages/Modulos/ModulosEdit";

import PerguntasList from "./pages/Perguntas/PerguntasList";
import PerguntaAdd from "./pages/Perguntas/PerguntaAdd";
import PerguntaEdit from "./pages/Perguntas/PerguntaEdit";

import TurmasList from "./pages/Turmas/TurmasList";
import TurmaAdd from "./pages/Turmas/TurmaAdd";
import TurmaEdit from "./pages/Turmas/TurmaEdit";

const routes = [
	{
		path: "/",
		exact: true,
		name: "Dashboard",
		icon: <AreaChartOutlined />,
		main: () => null,
	},

	{
		name: "Usuários",
		icon: <TeamOutlined />,
		path: "/usuarios/",
		children: [
			{
				path: "/usuarios/",
				exact: true,
				hideInMenu: false,
				icon: <OrderedListOutlined />,
				name: "Listar",
				main: UsuariosList,
			},
			{
				path: "/usuarios/add",
				exact: false,
				hideInMenu: false,
				icon: <PlusOutlined />,
				name: "Adicionar",
				main: UsuariosAdd,
			},
			{
				path: "/usuarios/editar/:id",
				exact: false,
				hideInMenu: true,
				icon: <UserOutlined />,
				name: "Editar",
				main: UsuariosEdit,
			},
		],
	},
	{
		name: "Turmas",
		icon: <SolutionOutlined />,
		path: "/turmas/",
		children: [
			{
				path: "/turmas/",
				exact: true,
				hideInMenu: false,
				icon: <OrderedListOutlined />,
				name: "Listar",
				main: TurmasList,
			},
			{
				path: "/turmas/add",
				exact: false,
				hideInMenu: false,
				icon: <PlusOutlined />,
				name: "Adicionar",
				main: TurmaAdd,
			},
			{
				path: "/turmas/editar/:id",
				exact: false,
				hideInMenu: true,
				icon: <UserOutlined />,
				name: "Editar",
				main: TurmaEdit,
			},
		],
	},
	{
		name: "Modulos",
		path: "/modulos/",
		icon: <ProfileOutlined />,
		children: [
			{
				path: "/modulos/",
				exact: true,
				hideInMenu: false,
				icon: <OrderedListOutlined />,
				name: "Listar",
				main: ModulosList,
			},
			{
				path: "/modulos/add",
				exact: false,
				hideInMenu: false,
				icon: <PlusOutlined />,
				name: "Adicionar",
				main: ModulosAdd,
			},
			{
				path: "/modulos/editar/:id",
				exact: false,
				hideInMenu: true,
				icon: <UserOutlined />,
				name: "Editar",
				main: ModulosEdit,
			},
		],
	},

	{
		name: "Aulas",
		path: "/aulas/",
		icon: <PlaySquareOutlined />,
		children: [
			{
				path: "/aulas/",
				exact: true,
				hideInMenu: false,
				icon: <OrderedListOutlined />,
				name: "Listar",
				main: AulasList,
			},
			{
				path: "/aulas/add",
				exact: false,
				hideInMenu: false,
				icon: <PlusOutlined />,
				name: "Adicionar",
				main: AulasAdd,
			},
			{
				path: "/aulas/editar/:id",
				exact: false,
				hideInMenu: true,
				icon: <UserOutlined />,
				name: "Editar",
				main: AulasEdit,
			},
		],
	},
	{
		name: "Exercicios",
		icon: <ReadOutlined />,
		path: "/exercicios/",
		children: [
			{
				path: "/exercicios/",
				exact: true,
				hideInMenu: false,
				icon: <OrderedListOutlined />,
				name: "Listar",
				main: PerguntasList,
			},
			{
				path: "/exercicios/add",
				exact: false,
				hideInMenu: false,
				icon: <PlusOutlined />,
				name: "Adicionar",
				main: PerguntaAdd,
			},
			{
				path: "/exercicios/editar/:id",
				exact: false,
				hideInMenu: true,
				icon: <UserOutlined />,
				name: "Editar",
				main: PerguntaEdit,
			},
		],
	},

	{
		path: "/usuario",
		name: "Perfil",
		exact: true,
		icon: <UserOutlined />,
		main: UserDetails,
	},
];

export default routes;
