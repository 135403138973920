import React from "react";
import Sider from "antd/es/layout/Sider";

import { Menu } from "antd";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

const MenuSidebar = ({ data }) => {
	const routes = data.route.routes;

	const module = data.location.pathname.split("/")?.filter((e) => e)[0];

	return (
		<Sider trigger={null} collapsible collapsed={data.collapsed}>
			<div className={"px-5 py-2"}>
				{data.collapsed ? (
					<img
						src={require("../assets/img/Ingles_Na_Vida_Reduzido.svg")}
						style={{ width: 100 }}
					/>
				) : (
					<img
						src={require("../assets/img/Ingles_Na_Vida_Completo.svg")}
						style={{ width: 100 }}
					/>
				)}
			</div>

			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={[data.location.pathname]}
				defaultOpenKeys={[`parent_/${module}/`]}
			>
				{routes.map((route) => {
					if (route.children && route.children.length) {
						console.log(
							"parent_${route.path}",
							`parent_${route.path}`
						);
						return (
							<SubMenu
								key={`parent_${route.path}`}
								icon={route.icon}
								title={route.name}
							>
								{route.children
									.filter((item) => !item.hideInMenu)
									.map((item) => (
										<Menu.Item
											icon={item.icon}
											key={item.path}
										>
											<Link to={item.path}>
												{item.name}
											</Link>
										</Menu.Item>
									))}
							</SubMenu>
						);
					}

					return (
						<Menu.Item icon={route.icon} key={route.path}>
							<Link to={route.path}>{route.name}</Link>
						</Menu.Item>
					);
				})}
			</Menu>
		</Sider>
	);
};

export default MenuSidebar;
