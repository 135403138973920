import React, { useState } from "react";

import http from "@/services/http";
import dayjs from "dayjs";
import {
	Tabs,
	Form,
	Card,
	Input,
	notification,
	Button,
	InputNumber,
	Row,
	Col,
	DatePicker,
	Select,
	Result,
	Spin,
	Switch,
} from "antd";

import { useMutation, queryCache, useQuery } from "react-query";
import { useRouteMatch, useHistory, generatePath } from "react-router-dom";
import AvatarUpload from "../../components/AvatarUpload";

const { Option } = Select;

const UsuariosEdit = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const { params } = useRouteMatch("/usuarios/editar/:id");

	const { data, isLoading, isFetching, refetch, error } = useQuery(
		"usuarios/" + params.id,
		(url) => http.get(url)
	);

	const [form] = Form.useForm();

	const onFinish = async (data) => {
		try {
			setLoading(true);

			await http.put("usuarios", { ...data, id: params.id });

			await queryCache.refetchQueries("usuarios");
			await queryCache.refetchQueries("usuarios/" + params.id);

			notification.open({
				message: "Usuário atualizado com sucesso",
				type: "success",
			});

			history.push("/usuarios");

			form.resetFields();
		} catch (error) {
			notification.open({
				message: "Erro inesperado ao atualizar o Usuário",
				type: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	async function validatorEmail(_, email) {
		const count = await http.get("/usuarios/email", {
			params: { email, id: params.id },
		});

		if (count) {
			return Promise.reject("Este e-mail já esta sendo usado no sistema");
		}

		return true;
	}

	if (error) {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	if (isLoading || !data) {
		return (
			<div
				style={{ minHeight: "60vh", width: "100%" }}
				className="flex items-center justify-center"
			>
				<Spin size={20} />
			</div>
		);
	}

	return (
		<Form
			form={form}
			onFinish={onFinish}
			layout="vertical"
			initialValues={{
				nome: data.nome,
				email: data.email,
				is_admin: data.is_admin,
				ativo: data.ativo,
				foto_url: data.foto_url,
			}}
		>
			<Card title="Editar usuários">
				<Row gutter={24}>
					<Col span={3}>
						<Form.Item name="foto_url">
							<AvatarUpload
								accept="image/*"
								value={form?.getFieldValue("foto_url")}
								onChange={(foto_url) => {
									form.setFieldsValue({ foto_url });
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={9}>
						<Form.Item
							name="nome"
							label="Nome"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="email"
							label="E-mail"
							rules={[
								{ type: "email", message: "E-mail inválido" },
								{
									validator: validatorEmail,
									validateTrigger: "onBlur",
								},
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input placeholder={"digite um email válido"} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={4}>
						<Form.Item name="is_admin" label={"Admin"}>
							<Switch defaultChecked={data.is_admin} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label={"Ativo"} name="ativo">
							<Switch defaultChecked={data.ativo} />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Salvar
					</Button>
					<Button
						className="ml-2"
						disabled={loading}
						onClick={() => history.push("/usuarios")}
						type="default"
						htmlType="button"
					>
						Voltar
					</Button>
				</Form.Item>
			</Card>
		</Form>
	);
};

export default UsuariosEdit;
