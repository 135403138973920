// the initial state of this reducer
export const INITIAL_STATE = {
	primary: "red",
	secondary: "#ffc013",
	gray100: "#999999",
	gray200: "#777777",
	gray300: "#555555",
	gray400: "#333333",
	gray500: "#111",
	error: "red"
};

export default INITIAL_STATE;
