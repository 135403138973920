import React from "react";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

const AuthLoading = () => {
	return (
		<div
			style={{
				flex: 1,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh"
			}}
		>
			<Spin
				size="large"
				indicator={() => (
					<LoadingOutlined style={{ fontSize: "60px" }} spin />
				)}
			/>
		</div>
	);
};

export default AuthLoading;
