import React, { useState } from "react";

import { Upload as UploadAnt, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = UploadAnt;

const { API_URL } = process.env;

// const types_accepts = {
// 	AUDIO: "audio/*",
// 	VIDEO: "video/mp4,video/x-m4v,video/*",
// 	IMAGE: "image/*",
// };

const Upload = ({ onChange, accept = "video/mp4,video/x-m4v,video/*" }) => {
	const [fileList, setFileList] = useState([]);

	const AUTH_TOKEN = localStorage.getItem("token");
	const propsDragger = {
		name: "file",
		accept,
		multiple: false,
		headers: {
			Authorization: `Bearer ${AUTH_TOKEN}`,
		},
		action: `${API_URL}/upload/`,
		onChange(info) {
			console.log("status", info);

			const { status, response } = info.file;

			setFileList([info.file]);

			if (status === "done") {
				const url = response.url;

				onChange(url);

				message.success(`Upload com sucesso .`);
			} else if (status === "error") {
				message.error(`Erro ao fazer upload desse arquivo.`);
			}
		},
	};

	return (
		<Dragger
			{...propsDragger}
			fileList={fileList}
			style={{
				width: "100%",
			}}
		>
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>
			<p className="ant-upload-text">
				Clique ou arreste para fazer upload do arquivo
			</p>
			<p className="ant-upload-hint">
				É permitido apenas um arquivo por vez
			</p>
		</Dragger>
	);
};

export default Upload;
