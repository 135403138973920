import { action } from "easy-peasy";

export default {
	filtros: {
		filiais: {},
		chamados: {},
	},

	setFiltrosFiliais: action((state, filiais) => {
		state.filtros.filiais = filiais;
	}),
	setFiltrosChamados: action((state, chamados) => {
		state.filtros.chamados = chamados;
	}),
};
