import React, { useEffect, useCallback } from "react";

import { Form, Input, Collapse, Button, Col, Row, Card, Checkbox } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { CheckCircleOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";

import Upload from "../../components/Upload";

const { Panel } = Collapse;

const ProvaOpcoesList = React.memo(({ field, index: indexProva, form }) => {
	const prova = form.getFieldsValue()["questionarios"][indexProva];
	const opcoes = prova["opcoes"];

	const handleClickOpcao = (index) => (e) => {
		e.persist();
		e.stopPropagation();

		const data = form.getFieldsValue();

		const opcao = data.questionarios[indexProva].opcoes[index];

		form.setFieldsValue({
			questionarios: data.questionarios.map((item, index) => {
				return index == indexProva
					? {
							...item,
							opcao_correta_id: opcao.id,
					  }
					: item;
			}),
		});
	};

	return (
		<Form.List
			name={[field.name, "opcoes"]}
			fieldKey={[field.fieldKey, "opcoes"]}
		>
			{(fields, { add, remove }) => (
				<div>
					<Collapse>
						{fields.map((field, index) => (
							<Panel
								header={
									<>
										{opcoes?.[index]?.id ==
										prova.opcao_correta_id ? (
											<CheckCircleOutlined
												style={{ color: "green" }}
											/>
										) : null}
										<span className="ml-2">Opção</span>
									</>
								}
								key={field.key}
								extra={
									<>
										<Button
											className="mr-5"
											type="primary"
											onClick={handleClickOpcao(index)}
										>
											Marcar como correta
										</Button>
										<Button
											onClick={() => {
												remove(index);
											}}
										>
											Excluir Opção
										</Button>
									</>
								}
							>
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item
											name={[field.name, "resposta"]}
											fieldKey={[
												field.fieldKey,
												"resposta",
											]}
											rules={[
												{
													required: true,
													message:
														"A resposta é obrigatória",
												},
											]}
										>
											<Input.TextArea placeholder="Resposta" />
										</Form.Item>
									</Col>
								</Row>
							</Panel>
						))}
					</Collapse>
					<Form.Item>
						<Button
							className="w-full mt-5"
							type="dashed"
							onClick={() => {
								add({
									id: uuid(),
									resposta: "",
								});
							}}
						>
							<PlusOutlined /> Adicionar Opção
						</Button>
					</Form.Item>
				</div>
			)}
		</Form.List>
	);
});

const FormProva = ({ form }) => {
	const setquestionariosItemByIndex = useCallback(
		(item, index) => {
			const { questionarios = [] } = form.getFieldsValue();

			const data = [...questionarios];

			data[index] = { ...data[index], ...item };

			form.setFieldsValue({
				questionarios: data,
			});
		},
		[form]
	);

	return (
		<>
			<Form.List name="questionarios">
				{(fields, { add, remove }) => (
					<div>
						<Collapse>
							{fields.map((field, index) => (
								<Panel
									key={field.key}
									header="Pergunta"
									extra={
										<Button onClick={() => remove(index)}>
											Excluir Pergunta
										</Button>
									}
								>
									<Row gutter={24}>
										<Col span={24}>
											<Form.Item
												name={[field.name, "enunciado"]}
												fieldKey={[
													field.fieldKey,
													"enunciado",
												]}
												rules={[
													{
														required: true,
														message:
															"O enunciado é obrigatório",
													},
												]}
											>
												<Input.TextArea placeholder="Enunciado" />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												name={[
													field.name,
													"arquivo_url",
												]}
												fieldKey={[
													field.fieldKey,
													"arquivo_url",
												]}
												label={"Audio ou Imagem"}
												placeholder="Url do arquivo"
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item shouldUpdate={false}>
												<Upload
													accept="image/*,audio/*"
													onChange={(arquivo_url) => {
														setquestionariosItemByIndex(
															{ arquivo_url },
															field.fieldKey
														);
													}}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<ProvaOpcoesList
												index={index}
												form={form}
												field={field}
											/>
										</Col>
									</Row>
								</Panel>
							))}
						</Collapse>
						<Form.Item>
							<Button
								className="mt-5 w-full"
								type="dashed"
								onClick={() => {
									add({
										id: uuid(),
										enunciado: "",
										opcao_correta_id: null,
									});
								}}
							>
								<PlusOutlined /> Adicionar Pergunta
							</Button>
						</Form.Item>
					</div>
				)}
			</Form.List>
		</>
	);
};

export default FormProva;
